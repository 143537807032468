import { MsalGuard } from '@azure/msal-angular';

import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { AdminNoAccessComponent } from '@components/admin-no-access/admin-no-access.component';

import { environment } from '../environments/environment';

import { IconsPageComponent } from './common/components/icons-page/icons-page.component';
import { PageNoAccessComponent } from './common/components/page-no-access/page-no-access.component';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { EAppRoute } from './common/models/app.routes.models';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: EAppRoute.Dashboard,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: EAppRoute.Admin,
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: '',
        redirectTo: EAppRoute.Dashboard,
        pathMatch: 'full',
      },
    ],
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(MsalGuard).canActivate(next, state),
    ],
  },
  {
    path: EAppRoute.NotFound,
    component: PageNotFoundComponent,
  },
  {
    path: EAppRoute.NoAccess,
    component: PageNoAccessComponent,
  },
  {
    path: EAppRoute.AdminNoAccess,
    component: AdminNoAccessComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

if (!environment.production) {
  routes.unshift({
    path: EAppRoute.Development,
    children: [{ path: EAppRoute.Icons, component: IconsPageComponent }],
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
